html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --flow-space: 0.5em;
  --text-color: rgb(255, 255, 255);
}

html {
  background-color: #1d1d1d;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  text-align: center;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f1e31;
  background: linear-gradient(0deg, #0f1e31 0%, #1a2747 57%, #313977 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

article {
  position: relative;
  z-index: 20;
}

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

h1,
h2,
h3,
p {
  font-size: clamp(var(--fluid-type-min, 1rem), 1rem + var(--fluid-type-target, 3vw), var(--fluid-type-max, 1.3rem));
}

h1 {
  --fluid-type-min: 3.3rem;
  --fluid-type-max: 8rem;
  --fluid-type-target: 8vw;
  max-width: 15ch;
  font-weight: 900;
  word-break: break-all;
}

p {
  --fluid-type-min: 1rem;
  --fluid-type-max: 2rem;
  --fluid-type-target: 2vw;
  font-weight: 300;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #62fade transparent;
}
.triangle-1 {
  border-width: 0 100px 173px 100px;
  top: 0;
  left: 0;
  z-index: 10;
  filter: blur(15px) brightness(1.08);
  transform: rotate3d(1, 1, 1, 33deg) translate3d(2vw, 80vh, 38vh);
}
.triangle-2 {
  border-width: 0 60px 120px 60px;
  top: 20vh;
  right: 10%;
  z-index: 2;
  filter: blur(10px);
  transform: rotate3d(0, 0, -1, 84deg);
  opacity: 0.4;
}
.triangle-3 {
  border-width: 0 20px 60px 20px;
  top: 50%;
  left: 30%;
  z-index: 80;
  filter: blur(2px), brightness(0.9);
  transform: rotate3d(1, 1, 2, -299deg);
}
/*# sourceMappingURL=index.ef85e9b5.css.map */
